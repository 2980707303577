* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  max-width: 100vw;
  direction: rtl;
  scroll-behavior: smooth;
}

/* google places API */
.pac-container {
  background-color: #fff;
  font-size: 18px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .pac-container::after {
  content: none;
} */

.pac-item {
  padding: 8px;
}

.pac-item:hover {
  background-color: #e5f6f8;
}
*:focus:not(button) {
  /* outline: 2px solid #00d6d1; */
  outline-offset: 2px;
  border: 1px dashed white;
}

*:focus-visible {
  outline: 2px solid #b0e6e4;
  border-radius: 4px;
  outline-offset: 2px;
  border: 1px dashed white;
}

[tabindex='0']:focus-visible {
  outline: 2px solid #b0e6e4;
  border-radius: 4px;
  outline-offset: 2px;
  border: 1px dashed white;
}

input:focus-visible {
  outline: 2px solid #b0e6e4;
  border-radius: 4px;
  outline-offset: 2px;
  border: 1px dashed white;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.genericContentPage a,
.genericContentPage a:visited {
  color: blue !important;
}

span[data-index='0'].MuiSlider-markLabel {
  transform-origin: 'left';
  transform: translate(-35px);
}

span[data-index='1'].MuiSlider-markLabel {
  transform-origin: 'right';
  transform: translate(1px);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 1s ease-out;
}
