@font-face {
  font-family: 'GillSans';
  src: url(./GillSans.woff);
  font-weight: normal;
  font-display: block;
}

/* @font-face {
  font-family: 'GillSansBold';
  src: url(./GillSansBold.woff);
  font-weight: bold;
  font-display: block;
} */

@font-face {
  font-family: 'EricaSansFOT';
  src: url(./EricaSansFOT-Regular.woff);
  font-weight: normal;
  font-display: block;
}

/* @font-face {
  font-family: 'EricaSansFOTBold';
  src: url(./EricaSansFOT-Bold.woff);
  font-weight: bold;
  font-display: block;
} */

@font-face {
  font-family: 'EricaSansLight';
  src: url(./EricaSansLight.woff);
  font-weight: normal;
  font-display: block;
}
